import Vue from 'vue';
export const EventBus = new Vue();

export function handleLangChange(action) {
    EventBus.$on('onLangChange', (id)=>{ 
        action(id);      
    });
    
}
export function handleDashLangChange(action) {
    EventBus.$on('onDashLangChange', (id)=>{ 
        action(id);      
    });
    
}
export function handleChangeUserInfo(action) {
    EventBus.$on('onChangeUserInfo', ()=>{ 
        action();      
    });
}
export function handleLoadReequest(action) {
    EventBus.$on('onLoadReequest', ()=>{ 
        action();
    });
}