<template>
    <div>
        <b-container class="pt-4">
            <section
                class="main-section black rounded d-flex p-3 pb-3 flex-column shadow overflow-hidden"
            >
                <svg class="corner corner-1" width="400" height="400">
                    <defs>
                        <linearGradient id="Gradient1">
                            <stop class="stop1" offset="0%" />
                            <stop class="stop1" offset="10%" />
                            <stop class="stop5" offset="100%" />
                        </linearGradient>
                        <linearGradient id="Gradient2">
                            <stop class="stop5" offset="0%" />
                            <stop class="stop1" offset="90%" />
                            <stop class="stop1" offset="100%" />
                        </linearGradient>
                    </defs>
                    <path
                        d="M70,20 h300 a10,10 0 0 1 10,10 v300 a10,0 10 0 0 1 -10"
                        fill="none"
                        stroke="url(#Gradient1)"
                        stroke-width="3"
                    />
                </svg>
                <svg class="corner corner-2" width="400" height="400">
                    <path
                        d="M20, 70 v300 a10,10 1 0 0 10,10 h300 a10,10 0 0 1 -10 0"
                        fill="none"
                        stroke="url(#Gradient2)"
                        stroke-width="3"
                    />
                </svg>
                <b-col cols="12">
                    <b-row class="my-2">
                        <b-col cols="12" md="6" order="1" order-md="0">
                            <div class="header-text">
                                <h1
                                    class="text-light text-left text-nowrap"
                                    data-aos="fade-right"
                                    data-aos-duration="6000"
                                >
                                    <span class="text-custom-blue-sky">{{ t("website.awija") }}</span>
                                    <br />
                                    {{ t("website.job") }}
                                </h1>
                                <p class="text-light my-3"
                                   data-aos="fade-right"
                                   data-aos-duration="4000">
                                    {{ t("website.job-sub") }}
                                </p>
                                <b-button
                                    data-aos="fade-right"
                                    data-aos-duration="2000"
                                    variant="outline-info"
                                    to="/products"
                                    size="lg"
                                >{{ t("website.goToStore") }}</b-button>
                            </div>
                        </b-col>
                        <b-col cols="12" md="6">
                            <div
                                class="h-100 w-100 mb-4 mx-auto d-flex align-items-center justify-content-center"
                            >
                                <img
                                    src="/media/logo/Logo only - white.svg"
                                    class="w-100"
                                    data-aos="fade-left"
                                    data-aos-duration="3000"
                                    style="max-width: 280px"
                                />
                            </div>
                        </b-col>
                    </b-row>
                </b-col>
            </section>
            <section class="servs-section py-4 overflow-hidden">
                <b-col cols="12">
                    <h1 class="m-0 mb-4 text-center text-light">{{ t("website.services") }}</h1>
                </b-col>
                <b-row>
                    <b-col class="m-auto my-4" cols="12" md="4" lg="4">
                        <div
                            class="border-wrapper my-2 my-md-0 serv-card m-auto"
                            data-aos="fade-right"
                            data-aos-duration="4000"
                        >
                            <router-link :to="{ path: '/services' }">
                                <b-card
                                    class="border-light borderd-card py-4 border-0 m-auto"
                                    footer-class=" p-0  border-0 d-flex aling-items-center justify-content-center "
                                    body-class="p-0 my-1 px-3 "
                                    header-class="p-0"
                                >
                                    <img
                                        src="/media/images/services/service_0.png"
                                        class="serv-icon icon1"
                                        alt
                                    />
                                    <template #header>
                                        <h3
                                            class="text-center text-light m-auto"
                                            v-if="homeDto.services[0]"
                                        >{{ homeDto.services[0].name }}</h3>
                                    </template>

                                    <p
                                        class="m-0 text-center text-light"
                                        v-if="homeDto.services[0]"
                                    >
                                        {{
                                            homeDto.services[0].description.slice(
                                                0,
                                                65
                                            )
                                        }}
                                    </p>
                                </b-card>
                            </router-link>
                        </div>
                    </b-col>

                    <b-col class="m-auto" cols="12" md="4" lg="4">
                        <div
                            class="border-wrapper my-2 my-md-0 serv-card m-auto"
                            data-aos="fade-up"
                            data-aos-duration="4000"
                        >
                            <router-link :to="{ path: '/services' }">
                                <b-card
                                    class="border-light borderd-card py-4 border-0 m-auto"
                                    footer-class=" p-0  border-0 d-flex aling-items-center justify-content-center "
                                    body-class="p-0 my-1 px-3 "
                                    header-class="p-0"
                                >
                                    <img
                                        src="/media/images/services/service_1.png"
                                        class="serv-icon icon2"
                                        alt
                                    />
                                    <template #header>
                                        <h3
                                            v-if="homeDto.services[1]"
                                            class="text-center text-light m-auto"
                                        >{{ homeDto.services[1].name }}</h3>
                                    </template>

                                    <p
                                        class="m-0 text-center text-light"
                                        v-if="homeDto.services[1]"
                                    >
                                        {{
                                            homeDto.services[1].description.slice(
                                                0,
                                                65
                                            )
                                        }}
                                    </p>
                                </b-card>
                            </router-link>
                        </div>
                    </b-col>

                    <b-col class="m-auto" cols="12" md="4" lg="4">
                        <div
                            class="border-wrapper my-2 my-md-0 serv-card m-auto"
                            data-aos="fade-left"
                            data-aos-duration="4000"
                        >
                            <router-link :to="{ path: '/services' }">
                                <b-card
                                    class="border-light borderd-card py-4 border-0 m-0"
                                    footer-class=" p-0  border-0 d-flex aling-items-center justify-content-center "
                                    body-class="p-0 my-1 px-3 "
                                    header-class="p-0"
                                >
                                    <img
                                        src="/media/images/services/service_2.png"
                                        class="serv-icon icon3"
                                        alt
                                    />
                                    <template #header>
                                        <h3
                                            class="text-center text-light m-auto"
                                            v-if="homeDto.services[2]"
                                        >{{ homeDto.services[2].name }}</h3>
                                    </template>

                                    <p
                                        class="m-0 text-center text-light"
                                        v-if="homeDto.services[2]"
                                    >
                                        {{
                                            homeDto.services[2].description.slice(
                                                0,
                                                65
                                            )
                                        }}
                                    </p>
                                </b-card>
                            </router-link>
                        </div>
                    </b-col>

                    <b-col cols="12" class="mt-1">
                        <div
                            class="d-flex align-items-ceneter justify-content-center w-100"
                            data-aos="fade-up"
                            data-aos-duration="4000"
                        >
                            <b-button
                                to="/services"
                                class="text-center text-custom-blue-sky m-auto"
                                variant="transparent"
                            >
                                {{ t("website.seeMore") }}
                                <unicon name="angle-left" class="arrow" fill="#1AC9FB"></unicon>
                            </b-button>
                        </div>
                    </b-col>
                </b-row>
            </section>
            <section class="products-section py-4 overflow-hidden">
                <b-col cols="12" class="d-lg-none">
                    <h2 class="text-light text-center mb-3">{{ t("website.latestProducts") }}</h2>
                </b-col>
                <section
                    class="main-section black product-slider rounded d-flex pb-4 pt-5 px-2 p-md-5 flex-column shadow"
                    data-aos="fade-up"
                    data-aos-duration="4000"
                >
                    <svg class="corner corner-1" width="400" height="400">
                        <path
                            d="M70,20 h300 a10,10 0 0 1 10,10 v300 a10,0 10 0 0 1 -10"
                            fill="none"
                            stroke="url(#Gradient1)"
                            stroke-width="3"
                        />
                    </svg>
                    <svg class="corner corner-2" width="400" height="400">
                        <path
                            d="M20, 70 v300 a10,10 1 0 0 10,10 h300 a10,10 0 0 1 -10 0"
                            fill="none"
                            stroke="url(#Gradient2)"
                            stroke-width="3"
                        />
                    </svg>

                    <b-row class="align-items-center">
                        <b-col cols="12" lg="4" class="h-100 d-none d-lg-block">
                            <div
                                class="text-light w-100 h-100 d-flex flex-column justify-content-center align-items-center"
                            >
                                <div class="info">
                                    <h2 class="text-light">{{ t("website.latestProducts") }}</h2>
                                    <b-button
                                        class="text-center text-custom-blue-sky m-auto"
                                        variant="transparent"
                                        to="/products"
                                    >
                                        {{ t("website.seeMore") }}
                                        <unicon name="angle-left" class="arrow" fill="#1AC9FB"></unicon>
                                    </b-button>
                                </div>
                            </div>
                        </b-col>

                        <b-col cols="12" md="6" lg="4">
                            <VueSlickCarousel
                                v-bind="settings2"
                                v-if="homeDto.mostSellingProducts.length"
                                ref="s1"
                            >
                                <div
                                    v-for="product in homeDto.mostSellingProducts"
                                    :key="product.id"
                                >
                                    <img
                                        v-if="product.images[0]"
                                        :src="
                                            $store.getters['app/domainHost'] +
                                            '/' +
                                            product.images[0]
                                        "
                                        class="w-100 slick-image"
                                    />
                                </div>
                            </VueSlickCarousel>
                        </b-col>

                        <b-col cols="12" md="6" lg="4" class="h-100">
                            <VueSlickCarousel
                                v-if="homeDto.mostSellingProducts.length"
                                v-bind="fadeSettings"
                                ref="s2"
                                @afterChange="setActiveProduct"
                            >
                                <div
                                    v-for="product in homeDto.mostSellingProducts"
                                    :key="product.id"
                                >
                                    <div class="d-flex flex-column align-items-center py-3">
                                        <div>
                                            <h3 class="text-light">
                                                {{ t("website.productName") }}:
                                                {{ product.name }}
                                            </h3>
                                            <h6 class="text-light">{{ product.mainCategory }}</h6>
                                            <h4 class="text-white price-range" v-if="!currType">
                                                <span>
                                                    {{
                                                        product.minCost
                                                            | numFormat('0,0.000')
                                                    }}
                                                    {{ t("sp") }}
                                                </span>
                                                <template v-if="product.maxCost">
                                                    -
                                                    <span>
                                                        {{
                                                            product.maxCost
                                                                | numFormat('0,0.000')
                                                        }}
                                                        {{ t("sp") }}
                                                    </span>
                                                </template>
                                            </h4>
                                            <h4 class="text-white price-range" v-else>
                                                <span>
                                                    {{
                                                        product.minCostDollar
                                                            | numFormat('0,0.000')
                                                    }}
                                                    {{ t("usd") }}
                                                </span>
                                                <template v-if="product.maxCostDollar">
                                                    -
                                                    <span>
                                                        {{
                                                            product.maxCostDollar
                                                                | numFormat('0,0.000')
                                                        }}
                                                        {{ t("usd") }}
                                                    </span>
                                                </template>
                                            </h4>
                                            <b-button
                                                class="text-left text-custom-blue-sky p-0 product-details-btn"
                                                variant="transparent"
                                                :to="'products/' + product.id"
                                            >
                                                <unicon
                                                    name="angle-left"
                                                    class="arrow"
                                                    fill="#1AC9FB"
                                                ></unicon>
                                                {{ t("website.details") }}
                                            </b-button>
                                        </div>
                                    </div>
                                </div>
                            </VueSlickCarousel>
                        </b-col>

                        <b-col cols="12">
                            <div class="d-flex justify-content-center pt-3">
                                <b-button @click="nextSlide" variant="transparent">
                                    <unicon
                                        height="20"
                                        class="arrow"
                                        fill="#eee"
                                        name="angle-right"
                                    ></unicon>
                                </b-button>
                                <b-button @click="prevSlide" variant="transparent">
                                    <unicon height="20" fill="#eee" class="arrow" name="angle-left"></unicon>
                                </b-button>
                            </div>
                        </b-col>
                    </b-row>
                </section>
                <div class="d-flex justify-content-center align-items-center d-lg-none">
                    <b-button
                        class="text-center text-custom-blue-sky"
                        variant="transparent"
                        to="/products"
                    >
                        {{ t("website.seeMore") }}
                        <unicon name="angle-left" class="arrow" fill="#1AC9FB"></unicon>
                    </b-button>
                </div>
            </section>
            <section
                class="activeties-section py-4 overflow-hidden"
                v-if="homeDto.latestActivities.length > 0"
            >
                <b-col cols="12">
                    <b-row>
                        <b-col cols="12" md="6" order="1" order-md="0">
                            <div class="d-flex flex-column">
                                <div class="img-wrapper rounded">
                                    <div class="actives-img">
                                        <b-img
                                            data-aos="fade-right"
                                            data-aos-duration="6000"
                                            v-if="homeDto.latestActivities[0]"
                                            :src="
                                                $store.getters[
                                                'app/domainHost'
                                                ] +
                                                '//' +
                                                homeDto.latestActivities[0]
                                                    .imagePath
                                            "
                                        ></b-img>
                                    </div>
                                </div>
                                <div
                                    class="mt-3 text-light"
                                    data-aos="fade-right"
                                    data-aos-duration="4000"
                                >
                                    <h3
                                        class="text-light"
                                        v-if="homeDto.latestActivities[0]"
                                    >{{ homeDto.latestActivities[0].title }}</h3>
                                    <p class="m-0" v-if="homeDto.latestActivities[0]">
                                        {{
                                            homeDto.latestActivities[0]
                                                .description | truncate(180)
                                        }}
                                    </p>
                                </div>
                            </div>
                            <b-button
                                class="text-center text-custom-blue-sky"
                                variant="transparent"
                                to="/activities"
                                data-aos="fade-right"
                                data-aos-duration="2000"
                            >
                                {{ t("website.readMore") }}
                                <unicon name="angle-left" class="arrow" fill="#1AC9FB" />
                            </b-button>
                        </b-col>
                        <b-col cols="12" md="6">
                            <div
                                class="w-100 h-100 d-flex flex-column align-items-center justify-content-center text-center text-light"
                                data-aos="fade-up"
                                data-aos-duration="4000"
                            >
                                <h2
                                    class="text-light mb-4 my-md-0"
                                >{{ t("website.shareActivities") }}</h2>
                                <b-button
                                    class="text-center d-none d-md-block text-custom-blue-sky"
                                    variant="transparent"
                                    to="/activities"
                                >
                                    {{ t("website.seeMore") }}
                                    <unicon name="angle-left" class="arrow" fill="#1AC9FB"></unicon>
                                </b-button>
                            </div>
                        </b-col>
                    </b-row>
                    <div
                        class="d-flex justify-content-center align-items-center"
                        data-aos="fade-up"
                        data-aos-duration="4000"
                    >
                        <b-button
                            class="text-center text-custom-blue-sky d-md-none"
                            variant="transparent"
                            to="/activities"
                        >
                            {{ t("website.seeMore") }}
                            <unicon name="angle-left" class="arrow" fill="#1AC9FB"></unicon>
                        </b-button>
                    </div>
                </b-col>
            </section>
        </b-container>
        <section
            class="clients-section mb-4 overflow-hidden"
            data-aos="fade-up"
            data-aos-duration="3500"
        >
            <div class="clients bg-light">
                <div class="w-100">
                    <VueSlickCarousel v-bind="settings" v-if="homeDto.clients.length">
                        <div
                            class="bg-cutsom-blue text-center"
                            v-for="client in homeDto.clients"
                            :key="client.id"
                        >
                            <b-button
                                v-b-popover.hover.top
                                variant="flat-secondary"
                                class="bg-transparent"
                                :title="client.name"
                            >
                                <img
                                    :src="
                                        $store.getters['app/domainHost'] +
                                        '//' +
                                        client.image
                                    "
                                />
                            </b-button>
                        </div>
                    </VueSlickCarousel>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import { useUtils as useI18nUtils } from "@core/libs/i18n";
import { handleLangChange } from "@/libs/event-bus";

export default {
    components: {
        VueSlickCarousel
    },
    setup() {
        const { t } = useI18nUtils();
        return { t };
    },
    data: () => ({
        activeProduct: null,
        fadeSettings: {
            arrows: false,
            infinite: true,
            speed: 700,
            slidesToShow: 1,
            rtl: true,
            slidesToScroll: 1
        },
        screenSize: ""
    }),

    computed: {
        ...mapState({
            homeDto: state => state.global.homeDto,
            currType: state => state.global.currType
        }),
        screen() {
            if (this.screenSize < 767) {
                return 1;
            } else if (this.screenSize < 992) {
                return 3;
            } else {
                return 5;
            }
        },
        settings() {
            return {
                infinite: true,
                initialSlide: 2,
                slidesToShow: this.screenSize < 767 ? 3 : 5,
                slidesToScroll: 1,
                autoplay: true,
                arrows: false
            };
        },
        settings2() {
            return {
                infinite: true,
                initialSlide: 2,
                speed: 800,
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: false,
                swipeToSlide: false,
                swipe: false,
                touchMove: false
            };
        }
    },
    created() {
        this.calcScreenSize();
        window.addEventListener("resize", () => {
            this.calcScreenSize();
        });
        this.getWebsiteHome();
        handleLangChange(() => {
            this.calcScreenSize();
            window.addEventListener("resize", () => {
                this.calcScreenSize();
            });
            this.getWebsiteHome();
        });
    },
    methods: {
        ...mapActions(["getWebsiteHome"]),
        calcScreenSize() {
            this.screenSize = window.innerWidth;
        },
        setActiveProduct(index) {
            this.activeProduct = this.homeDto.mostSellingProducts[index];
        },
        nextSlide() {
            this.$refs.s1.next();
            this.$refs.s2.next();
        },
        prevSlide() {
            this.$refs.s1.prev();
            this.$refs.s2.prev();
        }
    }
};
</script>

<style lang="scss" scoped>
.main-section {
    .hedaer-img {
        height: 300px;
        margin: auto;
    }
    min-height: 400px;
    margin: auto;

    background-size: cover;
    position: relative;

    @media screen and (min-width: 768px) {
        h1 {
            line-height: 1.2;
            font-size: 50px;
        }
    }
    .about-images {
        margin: auto;
        height: 300px;
        max-width: 300px;
        margin-bottom: 1rem;
        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }
}
.border-wrapper {
    border-radius: 15px !important;
    border: 0 !important;
    .borderd-card {
        border: 0 !important;
        border-radius: 10px !important;
        background-color: #4b4d4e;
        box-shadow: inset 10px 10px 5px -5px transparent,
            inset -10px -10px 5px -5px transparent;
        transition: 0.5s ease !important;

        button {
            margin: auto !important;
            text-align: center;
        }
        p {
            font-size: 16px;
            line-height: 1.8;
            font-weight: 200;
        }
    }
    background: rgb(204, 204, 204);
    background: linear-gradient(
        342deg,
        rgb(245, 245, 245) 0%,
        rgb(136, 136, 136) 25%,
        rgba(238, 238, 238, 1) 50%,
        rgb(136, 136, 136) 75%,
        rgb(238, 238, 238) 100%
    );
    padding: 5px;
    border-radius: 10px;
    transition: 0.5s ease !important;
    &:hover {
        transform: translateY(15px);
    }
    &:hover .borderd-card {
        box-shadow: inset 10px 10px 5px -5px rgb(255 255 255 / 30%),
            inset -10px -10px 5px -5px rgb(0 0 0 / 25%);
        background-color: rgb(85, 87, 88);
    }
}
.serv-card {
    max-width: 350px;
    margin: auto !important;
    margin-top: 20px !important;
    margin-bottom: 20px !important;
}
.serv-icon {
    position: absolute;
    top: -45px;
    z-index: 1;
}
.icon1 {
    left: 0;
    @media (max-width: 767px) {
        left: 50%;
        top: -40px;
        transform: translateX(-50%);
    }
}
.icon2 {
    right: 50%;
    top: -40px;
    transform: translateX(50%);
}
.icon3 {
    height: 60px;
    top: -40px;
    right: 20px;
    @media (max-width: 767px) {
        left: 50%;
        top: -40px;
        transform: translateX(-50%);
    }
}
.img-wrapper {
    overflow: hidden;
    width: 100%;
    max-width: 500px;
    .actives-img {
        width: 100%;
        height: 300px;
        transform: perspective(1000px) rotateY(330deg);
        border-radius: 10px;
        margin: 0 0 0 -70px;
        transform: skew(20deg);
        overflow: hidden;
        img {
            width: 100%;
            height: 100%;
            margin: 0 0 0 70px;
            transform: skew(-20deg);
            object-fit: cover;
        }
    }
}
@media screen and (max-width: 768px) {
    .img-wrapper {
        margin: auto;
    }
}
@media screen and (max-width: 576px) {
    .img-wrapper {
        .actives-img {
            margin: 0;
            transform: skew(0);
            img {
                margin: 0;
                transform: skew(0);
            }
        }
    }
}
.slick-image {
    margin: auto;
    max-width: 300px;
    height: 300px;
    object-fit: contain;
}
.clients-section {
    overflow: hidden;
    height: 272px;
    .clients {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 200px;
        transform: skew(0, -3deg);
        margin-left: -80px;
        margin-top: 36px;
        padding: 0 96px;
        width: calc(100% + 160px);
        img {
            height: 80px;
            margin: auto;
            transform: skewX(3deg);
        }
    }
}
html[dir="ltr"] {
    .arrow {
        transform: scaleX(-1);
    }
    .product-details-btn {
        direction: rtl;
    }
    .price-range {
        direction: ltr;
    }
}
html[dir="rtl"] {
    .price-range {
        direction: rtl;
    }
}

.silver {
    background-image: -webkit-repeating-linear-gradient(
            top,
            hsla(0, 0%, 100%, 0) 0%,
            hsla(0, 0%, 100%, 0) 3%,
            hsla(0, 0%, 100%, 0.1) 4.5%
        ),
        -webkit-repeating-linear-gradient(top, hsla(0, 0%, 0%, 0) 0%, hsla(
                        0,
                        0%,
                        0%,
                        0
                    )
                    2%, hsla(0, 0%, 0%, 0.03) 2.5%),
        -webkit-repeating-linear-gradient(top, hsla(0, 0%, 100%, 0) 0%, hsla(
                        0,
                        0%,
                        100%,
                        0
                    )
                    0.6%, hsla(0, 0%, 100%, 0.15) 1.2%),
        linear-gradient(
            80deg,
            #a6a6a6 0%,
            #d9d9d9 45%,
            #e0e0e0 55%,
            #e0e0e0 65%,
            #d9d9d9 75%,
            #a6a6a6 100%
        );
}

.black {
    background-image: linear-gradient(
        80deg,
        #222222 0%,
        #6f6f6f 40%,
        #858585 55%,
        #858585 65%,
        #6f6f6f 75%,
        #222222 100%
    );
}
</style>
<style lang="scss">
.popover-header {
    border-radius: 4px;
}
</style>
